<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <ASuccessFour :api="api"/>
        <v-skeleton-loader
            v-if="api.isLoading"
            type="table"
            ref="skeleton">

        </v-skeleton-loader>
        <v-row class="mt-3" v-else>
            <v-col align="center" justify="center" cols="12" sm="8" class="mx-auto">
                <v-col align="start" justify="start">
                    <v-btn
                    @click="redirectBack"
                    class="mb-3"
                    color="primary">
                        <v-icon>
                            mdi-arrow-left-bold
                        </v-icon>
                    </v-btn>
                </v-col>
                <v-card class="pa-3" elevation-1>
                    <!--BOC : Create a form here-->
                    <v-toolbar
                        class="secondary white--text pa-1">
                        <v-toolbar-title
                            class="text-h5">
                            New Referral Transaction
                        </v-toolbar-title>
                    </v-toolbar>
                    <div
                        class="mt-5">
                        <v-row>
                            <v-col>
                                <v-autocomplete
                                    dense 
                                    item-text="referral_uuid"
                                    item-value="referral_uuid"
                                    :items="referredAvailableOptions"
                                    outlined
                                    :label="(referralSettings.referred_user==1)?'New Referral ( This user will receive the points )':'New Referral ( This user will not receive any points )'"
                                    :filter="customFilter"
                                    :search-input.sync="searchReferred"
                                    return-object
                                    v-model="referredUser">
                                    <template
                                        v-slot:item="{item}">
                                        {{ item.email }} ( {{ item.referral_uuid }} )
                                    </template>
                                    <template
                                        v-slot:selection="{item}">
                                            {{ item.email }}
                                    </template>
                                </v-autocomplete>

                                <v-autocomplete
                                    dense 
                                    item-text="referral_uuid"
                                    item-value="referral_uuid"
                                    :items="usersAvailabelOptions"
                                    outlined
                                    :label="(referralSettings.referrer_user==1)?'Referred By ( This user will receive the points )':'Referred By ( This user will not receive any points )'"
                                    :filter="customFilter"
                                    :search-input.sync="searchReferral"
                                    return-object
                                    v-model="referralUser">
                                    <template
                                        v-slot:item="{item}">
                                        {{ item.email }} ( {{ item.referral_uuid }} )
                                    </template>
                                    <template
                                        v-slot:selection="{item}">
                                            {{ item.email }}
                                    </template>
                                </v-autocomplete>

                                <v-text-field
                                    type="number"
                                    disabled 
                                    dense  
                                    label="Points ( Contact Developer to change it)"
                                    outlined 
                                    v-model="this.referralSettings.referral_points">

                                </v-text-field>
                                <div 
                                    class="d-flex justify-end">
                                    <v-btn 
                                        color="primary"
                                        plain
                                        @click="()=>{
                                            $router.go(-1)
                                        }">
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        color="primary"
                                        @click="validateInput">
                                        Confirm
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                    <!--EOC-->
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import AConfirmation from '../../components/common/AConfirmation.vue';
import ASuccessFour from '../../components/common/ASuccessFour.vue';
export default {
    components:{
    AConfirmation,
    ASuccessFour
},
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
        searchReferred:"",
        searchReferral:"",
        isPending:false,
        referralUser:null,
        referredUser:null,
        referralSettings:null,
        usersAvailabelOptions:null,
        referredAvailableOptions:null,
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class==="getAllAvailabelReferralUser"){
                this.usersAvailabelOptions = resp.data;
                let tempUser = resp.data;
                let referredUser = tempUser.filter(function(item) {
                    if(item.referral_by_user_uuid==null) {
                        return true;
                    }
                })
                this.referredAvailableOptions = referredUser;

            }
            if(resp.class==="getReferralSettings"){
                this.referralSettings  = resp.data;
            }
            if(resp.class==="addReferralPoints"){
                this.api.isSuccesful = true;
                this.api.success = "Succesfully Add New Referral Points";
            }
            this.api.isLoading = false;
            this.api.isError = false;
            this.isSucessful = true;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            let fetchUserAvailableReferralApi = this.fetchUserAvailableReferral();
            let fetchReferralSettingsApi = this.fetchReferralSettings();
            this.$axios.all([
                this.$api.fetch(fetchReferralSettingsApi),
                this.$api.fetch(fetchUserAvailableReferralApi),

            ]);

        },
        fetchUserAvailableReferral(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method ="GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/referral/user/available";
            return tempApi;
        },
        fetchReferralSettings(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method ="GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/referral/settings";
            return tempApi;
        },
        validateInput(){
            this.isPending = true;
        },
        submit()
        {
            this.isPending = false;
            let createApi = this.create();
            this.$api.fetch(createApi);
            
            
        },
        cancelSubmit(){
            this.isPending = false;
        },
        create(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "POST";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/referral/transaction";
            let formData = new FormData;
            formData.append('referral_id',this.referralUser.user_id);
            formData.append('referred_id',this.referredUser.user_id);
            formData.append('points',this.referralSettings.referral_points);
            tempApi.params = formData;
            return tempApi;
        },
        customFilter(item,queryText){
            const searchText = queryText.toLowerCase();
            const itemEmail = item.email.toLowerCase();
            const itemUuid = item.referral_uuid.toLowerCase();

            return itemEmail.includes(searchText) || itemUuid.includes(searchText);

        },
        redirectBack(){
            this.$router.go(-1);
        }
    }
}
</script>